import React from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitExercise } from "../hooks/useSubmitExercise";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import FeedbackSpeaking from "./FeedbackSpeaking";
import AudioRecorder from "./AudioRecorder";
import CorrectedResponse from "./CorrectedResponse";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Image from "./Image";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import Toolbar from "./Toolbar";
import ExerciseUsage from "./ExerciseUsage";

const SpeakingExercise = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { result, setResult, isSubmitting, submitExercise } = useSubmitExercise();

  const handleStopRecording = (blob) => {
    const formData = new FormData();
    formData.append("exerciseId", data._id);
    formData.append("instructions", data.content.instructions);
    formData.append("prompt", data.content.prompt);
    formData.append("imageDescription", data.content.imageDescription);
    formData.append("audio", blob, `audio.${blob.type.split("/")[1]}`);
    submitExercise(`${api}`, formData, 'multipart/form-data');
  };

  const handleRefresh = () => { setResult(null); refetchData(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="speaking" style={{ marginBottom: '6px' }} trigger={result} />
      <Toolbar data={data} />
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && <>
        <Instructions instructions={data.content.instructions} />
        {data.content.prompt && <Prompt prompt={data.content.prompt} />}
        {data.content.imageUrl && <Image url={data.content.imageUrl} />}
        <AudioRecorder onStopRecording={handleStopRecording} isSubmitting={isSubmitting} />
        {result && <FeedbackSpeaking feedback={result} />}
      </>}
    </div>
  );
};

export default SpeakingExercise;

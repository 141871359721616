import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import styles from '../styles/USPs.module.css'; // Import the CSS module
import 'bootstrap-icons/font/bootstrap-icons.css';

const USPs = () => {
  const { t } = useTranslation(); // Initialize the translation function

  const usps = [
    { icon: 'bi-sliders', text: t('personalised_exercises'), link: '#personalised-exercises' },
    { icon: 'bi-mic', text: t('speaking_feedback'), link: '#speaking-feedback' },
    { icon: 'bi-pencil-square', text: t('essay_corrections'), link: '#essay-corrections' },
    { icon: 'bi-globe', text: t('50_languages'), link: '#50-languages' },
    { icon: 'bi-list-check', text: t('25_exercise_types'), link: '#25-exercise-types' },
  ];

  return (
    <div className="container text-center mt-2 mb-3 px-0" style={{ maxWidth: '720px' }}>
      <div className="row row-cols-3 row-cols-md-5 g-2 g-md-3 justify-content-center">
        {usps.map((usp, index) => (
          <div key={index} className="col">
            <a href={usp.link} className="text-decoration-none">
              <div className={styles.uspItem}>
                <i className={`bi ${usp.icon} ${styles.icon}`}></i>
                <p className={`${styles.text} mb-0`}>{usp.text}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default USPs;

import React from 'react';

const Table = ({ table }) => {
  return (
    table ? (
      <div className="alert alert-light">
        <div className="text-center fs-4 mb-2" style={{ color: 'black' }}>{table.title}</div>
        <table className="table table-bordered">
          <thead>
            <tr>
              {table.columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : null
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import GapItem from "./GapItem";

const areAllGapsFilled = (userAnswers, gaps) => {
  return gaps.every((_, index) => userAnswers[index] && userAnswers[index].userAnswer);
};

const calculateScore = (userAnswers, gaps) => {
  return Object.values(userAnswers).filter(answer => answer.isCorrect).length / gaps.length;
};

const GapFillingPassage = ({ passage, gaps, onComplete, resetTrigger }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  useEffect(() => {
    setUserAnswers({});
    setExplanations([]);
  }, [resetTrigger, passage]);

  const handleOptionChange = (gapIndex, selectedOption) => {
    const isCorrect = selectedOption === gaps[gapIndex].answer;

    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [gapIndex]: { userAnswer: selectedOption, isCorrect } };

      // Update explanations with new explanation for the current answer
      const newExplanation = (
        <p key={gapIndex}>
          <strong>({gapIndex + 1})</strong> {gaps[gapIndex].explanation}
        </p>
      );
      setExplanations((prevExplanations) => [...prevExplanations, newExplanation]);

      if (areAllGapsFilled(newAnswers, gaps)) {
        const score = calculateScore(newAnswers, gaps);
        onComplete(score);
      }

      return newAnswers;
    });
  };

  const renderGapSelect = (gap, gapIndex) => {
    return (
      <span key={gapIndex} style={{ display: "inline-block", margin: "0 5px" }}>
        <GapItem
          gapData={gap}
          gapIndex={gapIndex}
          selectedOption={userAnswers[gapIndex]?.userAnswer}
          isCorrect={userAnswers[gapIndex]?.isCorrect}
          onOptionChange={handleOptionChange}
        />
      </span>
    );
  };

  const renderFilledPassage = () => {
    let splitPassage = passage.content.split(/_/); // Split passage by blank placeholders
    return (
      <div className="alert alert-light" style={{ textAlign: "justify" }}>
        {passage.title && <div className="fs-4 mb-3 text-center">{passage.title}</div>}
        {splitPassage.map((text, index) => (
          <span key={index}>
            {/* Render text and handle new lines */}
            {text.split('\n').map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                {line}
                {lineIndex < text.split('\n').length - 1 && <br />} {/* Only add <br /> if it's not the last line */}
              </React.Fragment>
            ))}
            {index < gaps.length && renderGapSelect(gaps[index], index)} {/* Render gaps inline */}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div>
      {renderFilledPassage()}
      {explanations.length > 0 && (
        <div className="mt-4 alert alert-warning">
          <h2 className="text-center">Explanations</h2> {/* Title for explanations */}
          {explanations} {/* Separate explanations into paragraphs */}
        </div>
      )}
    </div>
  );
};

export default GapFillingPassage;

import React, { useState, useEffect } from "react";
import Input from "./Input"; // You already have an Input component

// Helper function to check if all answers are submitted
const areAllAnswersSubmitted = (userAnswers, questions) => {
  return questions.every((_, index) => userAnswers[index]?.submitted);
};

// Helper function to calculate the score
const calculateScore = (userAnswers, questions) => {
  return questions.filter(
    (_, index) => userAnswers[index]?.isCorrect
  ).length / questions.length;
};

const ShortAnswerQuestions = ({ short_answer_questions, onComplete, resetTrigger, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});

  // Reset the answers when resetTrigger changes (e.g. when trying again)
  useEffect(() => {
    setUserAnswers({});
  }, [resetTrigger]);

  // Handle input change for each short answer (using the question index)
  const handleInputChange = (index, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  // Handle submission for each short answer (using the question index)
  const handleSubmit = (index) => {
    const userAnswer = userAnswers[index]?.userAnswer;

    // Find the correct answer for this specific question
    const correctAnswer = short_answer_questions[index].correctAnswer;

    if (userAnswer) {
      const isCorrect = userAnswer.trim().toLowerCase() === correctAnswer.toLowerCase();

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [index]: {
            userAnswer,
            isCorrect,
            submitted: true,
            explanation: short_answer_questions[index].explanation,
          },
        };

        // Highlight the relevant part of the passage based on the reference
        onHighlightChange(short_answer_questions[index].reference);

        // If all answers are submitted, calculate and submit the score
        if (areAllAnswersSubmitted(updatedAnswers, short_answer_questions)) {
          const score = calculateScore(updatedAnswers, short_answer_questions);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  // Rendering the short answer questions with input fields and feedback
  const renderQuestions = () => (
    <div>
      <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
        {short_answer_questions.map((questionObj, index) => (
          <li key={index} className="mb-3">
            <div style={{ display: "inline-flex", alignItems: "center", gap: "10px" }}>
              <div>{questionObj.question}</div>
              {/* Inline input field */}
              <Input
                userAnswer={userAnswers[index]?.userAnswer}
                onInputChange={(value) => handleInputChange(index, value)}
                onSubmit={() => handleSubmit(index)}
                isSubmitted={userAnswers[index]?.submitted}
                isCorrect={userAnswers[index]?.isCorrect}
                correctAnswer={questionObj.correctAnswer}
              />
            </div>
            {/* Show explanation if answer is submitted, always in alert-warning */}
            {userAnswers[index]?.submitted && (
              <div className="alert alert-warning mt-2">
                {userAnswers[index].explanation}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  return <div>{renderQuestions()}</div>;
};

export default ShortAnswerQuestions;

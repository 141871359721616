import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitExercise } from "../hooks/useSubmitExercise";
import WritingArea from "./WritingArea";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import FeedbackWriting from "./FeedbackWriting";
import Instructions from "./Instructions";
import Prompt from "./Prompt";
import Table from "./Table";
import SubmitButton from "./SubmitButton";
import Graph from "./Graph";
import Chart from "./Chart";
import ExerciseTitle from "./ExerciseTitle";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import HighlightedPassage from "./HighlightedPassage";
import Toolbar from "./Toolbar";
import ExerciseUsage from "./ExerciseUsage";
const WritingExercise = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { result, setResult, isSubmitting, submitExercise } = useSubmitExercise();
  const [essayText, setEssayText] = useState('');

  const handleEssayChange = (text) => { setEssayText(text); };

  const handleSubmit = () => {
    submitExercise(api, {
      'exerciseId': data._id,
      'instructions': data.content.instructions,
      'prompt': data.content.prompt,
      'passage': data.content.passage,
      'table': data.content.table,
      'response': essayText
    })
    .then((response) => { setEssayText(response.correction); });
  };

  const handleRefresh = () => { setEssayText(''); setResult(null); refetchData(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="writing" style={{ marginBottom: '6px' }} trigger={result} />
      <Toolbar data={data} />
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && <>
        <Instructions instructions={data.content.instructions} />
        {data.content.prompt && <Prompt prompt={data.content.prompt} />}
        {data.content.passage && <HighlightedPassage passage={data.content.passage} />}
        {data.content.table && <Table table={data.content.table} />}
        {data.content.graph && <Graph graph={data.content.graph} />}
        {data.content.chart && <Chart chart={data.content.chart} />}
        <WritingArea value={essayText} onChange={handleEssayChange} />
        <SubmitButton onClick={handleSubmit} isSubmitting={isSubmitting} />
        {result && <FeedbackWriting feedback={result} />}
      </>}
    </div>
  );
};

export default WritingExercise;

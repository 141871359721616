import React from "react";

const Explanations = ({ explanations }) => {
  // Sort explanations by the "number" property
  const sortedExplanations = [...explanations].sort((a, b) => a.number - b.number);

  return (
    <div className="mt-4 alert alert-warning pb-0">
      <div className="fs-3 mb-3 text-center">Explanations</div>
      {sortedExplanations.map((explanation, index) => (
        <p key={index}>
          <strong>({explanation.number})</strong> {explanation.text}
        </p>
      ))}
    </div>
  );
};

export default Explanations;

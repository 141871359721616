import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import QuestionsList from "./QuestionsList";
import BlanksList from "./BlanksList";
import Transcript from "./Transcript";
import AudioPlayer from "./AudioPlayer";
import Instructions from "./Instructions";
import ExerciseTitle from "./ExerciseTitle";
import TableCompletion from "./TableCompletion";
import NoteCompletion from "./NoteCompletion";
import SummaryCompletion from "./SummaryCompletion";
import ShortAnswerQuestions from "./ShortAnswerQuestions";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import Toolbar from "./Toolbar";
import ExerciseUsage from "./ExerciseUsage";

const ListeningExercise = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore( data._id, calculatedScore); }
  const handleRefresh = () => { setHighlight(""); setScore(null); setResetTrigger((prev) => !prev); refetchData(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="listening" style={{ marginBottom: '6px' }} trigger={data} />
      <Toolbar data={data} />
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && <>
        <Instructions instructions={data.content.instructions} />
        <AudioPlayer src={data.content.audioUrl} />
        <Transcript conversation={data.content.conversation} highlight={highlight} />
        {data.content.multiple_choice && <QuestionsList
          questions={data.content.multiple_choice}
          onHighlightChange={setHighlight}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
        />}
        {data.content.sentence_completion && <BlanksList
          blanks={data.content.sentence_completion}
          onHighlightChange={setHighlight}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
        />}
        {data.content.table_completion && <TableCompletion
          table={data.content.table_completion.table}
          onHighlightChange={setHighlight}
          answers={data.content.table_completion.answers}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
        />}
        {data.content.note_completion && <NoteCompletion
          notes={data.content.note_completion.notes}
          answers={data.content.note_completion.answers}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
          onHighlightChange={setHighlight}
        />}
        {data.content.summary_completion && <SummaryCompletion
          summary={data.content.summary_completion.summary}
          answers={data.content.summary_completion.answers}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
          onHighlightChange={setHighlight}
        />}
        {data.content.short_answer_questions && <ShortAnswerQuestions
          short_answer_questions={data.content.short_answer_questions}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
          onHighlightChange={setHighlight}
        />}
      </>}
    </div>
  );
};

export default ListeningExercise;

import React from 'react';

const Instructions = ({ instructions }) => {
  return (
    instructions ? (
      <div className="m-3 fst-italic">
        {instructions}
      </div>
    ) : null
  );
};

export default Instructions;

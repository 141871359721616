import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next'; 

const PremiumPage = () => {
  const { t } = useTranslation();
  return (
    <div className="container col-xxl-8 mt-5">
      <h1 className="text-center mb-4">{t('upgrade_to_premium')}</h1>
      <p className="text-center mb-3 fs-5">{t('premium_description')}</p>
      <div className="col d-flex">
        <div className="card-body d-flex flex-column">
          {/* Price Section */}
          <div className="mb-4 mt-3 d-flex flex-column align-items-center justify-content-center">
            <h1 className="card-title pricing-card-title fw-normal display-4 d-flex flex-wrap justify-content-center align-items-baseline">
              <span style={{ textDecoration: 'line-through', color: '#999', fontSize: '3.2rem' }}>
                {t('pricing_premium_original_price')}
              </span>
              &nbsp;
              <span className="d-flex align-items-baseline">
                <span style={{ fontSize: '3.2rem' }}>
                  {t('pricing_premium_price')}
                </span>
                <small className="text-body-secondary fw-light" style={{ fontSize: '2rem' }}>
                  {t('pricing_premium_duration')}
                </small>
              </span>
            </h1>
            <p className="text-danger mb-0 fs-4 mt-2">{t('pricing_premium_discount')}</p>
          </div>
          {/* Features Section */}
          <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column align-items-center justify-content-center fs-4" style={{ color: '#0356d1' }}>
            <li className="mb-2"><i className="bi bi-book me-2"></i>{t('pricing_premium_reading')}</li>
            <li className="mb-2"><i className="bi bi-headphones me-2"></i>{t('pricing_premium_listening')}</li>
            <li className="mb-2"><i className="bi bi-pencil me-2"></i>{t('pricing_premium_writing')}</li>
            <li className="mb-2"><i className="bi bi-mic me-2"></i>{t('pricing_premium_speaking')}</li>
          </ul>
          {/* CTA Section */}
          <div className="text-center mt-2">
            <Link to="/upgrade" className="btn btn-primary btn-lg fs-3">{t('upgrade_now')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Pricing = () => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <div className="container col-xxl-8 px-4 pt-5">
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h2 className="display-4 fw-normal text-body-emphasis">{t('pricing_title')}</h2>
        <p className="fs-5 text-body-secondary">
          {t('pricing_subtitle')}
        </p>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 text-center mb-3">
        {/* Basic Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm h-100 d-flex flex-column">
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">{t('pricing_basic_plan')}</h4>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Price Section */}
              <div className="mb-4 mt-3 d-flex align-items-center justify-content-center" style={{ height: '50px' }}>
                <h1 className="card-title pricing-card-title fw-normal">{t('pricing_basic_price')}</h1>
              </div>
              {/* Features Section */}
              <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '120px', fontSize: '1.1rem' }}>
                <li><i className="bi bi-book me-2"></i>{t('pricing_basic_reading')}</li>
                <li><i className="bi bi-headphones me-2"></i>{t('pricing_basic_listening')}</li>
                <li><i className="bi bi-pencil me-2"></i>{t('pricing_basic_writing')}</li>
                <li><i className="bi bi-mic me-2"></i>{t('pricing_basic_speaking')}</li>
              </ul>
              {/* CTA Section */}
              <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                <Link to="/signup" className="w-100 btn btn-lg btn-outline-primary">{t('pricing_basic_button')}</Link>
              </div>
            </div>
          </div>
        </div>
        {/* Premium Plan */}
        <div className="col d-flex">
          <div className="card w-100 rounded-3 shadow-sm border-primary h-100 d-flex flex-column">
            <div className="card-header py-3 text-bg-primary border-primary">
              <h4 className="my-0 fw-normal">{t('pricing_premium_plan')}</h4>
            </div>
            <div className="card-body d-flex flex-column">
              {/* Price Section */}
              <div className="mb-4 mt-3 d-flex flex-column align-items-center justify-content-center" style={{ height: '50px' }}>
                <h1 className="card-title pricing-card-title fw-normal">
                  <span style={{ textDecoration: 'line-through', color: '#999' }}>{t('pricing_premium_original_price')}</span>&nbsp;
                  {t('pricing_premium_price')}
                  <small className="text-body-secondary fw-light">{t('pricing_premium_duration')}</small>
                </h1>
                <p className="text-danger mb-0 fs-5">{t('pricing_premium_discount')}</p>
              </div>
              {/* Features Section */}
              <ul className="list-unstyled mb-4 flex-grow-1 d-flex flex-column justify-content-center" style={{ height: '120px', fontSize: '1.1rem', color: '#0356d1' }}>
                <li><i className="bi bi-book me-2"></i>{t('pricing_premium_reading')}</li>
                <li><i className="bi bi-headphones me-2"></i>{t('pricing_premium_listening')}</li>
                <li><i className="bi bi-pencil me-2"></i>{t('pricing_premium_writing')}</li>
                <li><i className="bi bi-mic me-2"></i>{t('pricing_premium_speaking')}</li>
              </ul>
              {/* CTA Section */}
              <div className="mt-auto d-flex align-items-center justify-content-center" style={{ height: '40px' }}>
                <Link to="/signup" className="w-100 btn btn-lg btn-primary">{t('pricing_premium_button')}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;

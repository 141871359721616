import React, { useContext, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import SignUpNow from '../components/SignUpNow';
import Testimonials from '../components/Testimonials';
import AboutUs from '../components/AboutUs';
import ExamLogos from '../components/ExamLogos';

const LandingPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const testimonialsRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutUsRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);
  const personalisedExercisesRef = useRef(null);
  const speakingFeedbackRef = useRef(null);
  const essayCorrectionsRef = useRef(null);
  const languagesRef = useRef(null);
  const exerciseTypesRef = useRef(null);
  const location = useLocation();

  // Redirect to /home if the user is already logged in
  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  // Scroll to sections based on hash in URL
  useEffect(() => {
    if (location.hash === '#testimonials') { testimonialsRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#features') { featuresRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#about-us') { aboutUsRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#pricing') { pricingRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#faq') { faqRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#personalised-exercises') { personalisedExercisesRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#speaking-feedback') { speakingFeedbackRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#essay-corrections') { essayCorrectionsRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#50-languages') { languagesRef.current.scrollIntoView({ behavior: 'smooth' }); }
    if (location.hash === '#25-exercise-types') { exerciseTypesRef.current.scrollIntoView({ behavior: 'smooth' }); }
  }, [location]);

  return (
    <>
      <Hero />
      {/* <ExamLogos /> */}
      <div ref={testimonialsRef}><Testimonials /></div>
      <div ref={featuresRef}>
        <Features
          personalisedExercisesRef={personalisedExercisesRef}
          speakingFeedbackRef={speakingFeedbackRef}
          essayCorrectionsRef={essayCorrectionsRef}
          languagesRef={languagesRef}
          exerciseTypesRef={exerciseTypesRef}
        />
      </div>
      <div ref={aboutUsRef}><AboutUs /></div>
      <div ref={pricingRef}><Pricing /></div>
      <div ref={faqRef}><FAQ /></div>
      <div><SignUpNow /></div>
    </>
  );
};

export default LandingPage;

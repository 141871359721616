import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ExerciseUsage = ({ category, style, trigger }) => {
  const [usageData, setUsageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsage = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/user/exercise-usage/${category}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsageData(response.data);
      } catch (err) {
        console.error('Error fetching exercise usage:', err);
        setError('Failed to load exercise usage.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsage();
  }, [category, trigger]);

  if (loading) {
    return <div className="spinner-border text-primary" role="status"></div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  const { used, limit } = usageData;
  const progress = (used / limit) * 100;

  return (
    <div className="mt-2" style={style}>
      <div className="progress" style={{ height: '20px', position: 'relative' }}>
        {/* Filled part of the progress bar */}
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={used}
          aria-valuemin="0"
          aria-valuemax={limit}
        >
          {/* Conditionally hide the text if progress is below a readable threshold */}
          {progress >= 20 ? `${used} / ${limit}` : null}
        </div>
        
        {/* Centered text for the entire bar, visible when progress is small */}
        {progress < 20 && (
          <span 
            style={{
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              textAlign: 'center',
              color: '#000',
            }}
          >
            {`${used} / ${limit}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default ExerciseUsage;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import SettingsPage from './pages/SettingsPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import VerifyEmailPage from './pages/VerifyEmailPage';
import UpgradePage from './pages/UpgradePage';
import PremiumRoute from './components/PremiumRoute';
import PremiumPage from './pages/PremiumPage';
import FeedbackButton from './components/FeedbackButton';
import './styles/App.css';
// import Exam from './components/Exam';
// import ExamsPage from './pages/ExamsPage';
// import IeltsExam from './components/IeltsExam';
// import ExercisesPage from './pages/ExercisesPage';
import Exercise from './components/Exercise';
import HomePage from './pages/HomePage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<><LoginPage /><FeedbackButton /></>} />
          <Route path="/signup" element={<><SignupPage /><FeedbackButton /></>} />
          <Route path="/verify-email" element={<><VerifyEmailPage /><FeedbackButton /></>} />
          <Route path="/upgrade" element={<><UpgradePage /><FeedbackButton /></>} />
          <Route path="/*" element={<MainLayout />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => (
  <div className="d-flex flex-column min-vh-100">
    <Header />
    <div className="flex-grow-1">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
        <Route path="/premium" element={<PrivateRoute><PremiumPage /></PrivateRoute>} />
        <Route path="/:section/:exercise" element={<PrivateRoute><Exercise /></PrivateRoute>} />
        {/* <Route path="/exercises" element={<PrivateRoute><ExercisesPage /></PrivateRoute>} /> */}
        {/* <Route path="/ielts" element={<PrivateRoute><IeltsExam /></PrivateRoute>} /> */}
        {/* <Route path="/exercises/:section/:exercise" element={<PrivateRoute><Exercise /></PrivateRoute>} /> */}
        {/* <Route path="/exercises/:level/:section/:exercise" element={<PrivateRoute><Exercise /></PrivateRoute>} /> */}
        {/* <Route path="/exams" element={<PrivateRoute><ExamsPage /></PrivateRoute>} /> */}
        {/* <Route path="/exams/:exam/:level" element={<PrivateRoute><Exam /></PrivateRoute>} /> */}
        </Routes>
    </div>
    <Footer />
    <FeedbackButton />
  </div>
);

export default App;

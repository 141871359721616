import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';  // Make sure Bootstrap is imported if you're using it

const GapItem = ({ gapData, gapIndex, selectedOption, isCorrect, onOptionChange }) => {
  const [shuffledOptions, setShuffledOptions] = useState([]);

  useEffect(() => {
    // Shuffle the options array when the component mounts
    const shuffled = [...gapData.options].sort(() => Math.random() - 0.5);
    setShuffledOptions(shuffled);
  }, [gapData.options]);

  const handleSelectChange = (e) => {
    onOptionChange(gapIndex, e.target.value);
  };

  return (
    <span>
      <strong>({gapIndex + 1})</strong> {/* Numbering the blanks with bold */}
      {selectedOption ? (
        // Apply underline to the entire gap using border-bottom for more control
        <span style={{ borderBottom: '1px solid black', paddingBottom: '2px' }}>&nbsp;
          {isCorrect ? (
            <span style={{ color: '#198754' }}>{selectedOption}&nbsp;</span>  // Correct answer in green (#198754)
          ) : (
            <span>
              <span style={{
                color: '#dc3545',
                textDecorationLine: 'line-through',  // Strike-through only
                textDecorationColor: '#dc3545',  // Strike-through in red
              }}>
                <span>{selectedOption}</span>
              </span>
              <span style={{ color: '#198754' }}> {gapData.answer}&nbsp;</span></span>
          )}
        </span>
      ) : (
        // Nicer dropdown with Bootstrap styles and custom CSS
        <select
          onChange={handleSelectChange}
          value=""
          className="form-select form-select-sm mx-1" // Use Bootstrap classes for a styled dropdown
          style={{
            display: 'inline-block', // Keep inline so it doesn't take up full width
            width: 'auto', // Set width to auto to avoid filling 100%
            padding: '0.175rem 0.75rem',
            fontSize: '0.875rem',
            lineHeight: '1.5',
            color: '#495057',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ced4da',
            borderRadius: '0.25rem',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            cursor: 'pointer',
            minWidth: '100px',
            margin: '0.175rem 0'
          }}
        >
          <option value="" disabled>Select</option>
          {shuffledOptions.map((option, optIndex) => (
            <option key={optIndex} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
    </span>
  );
};

export default GapItem;

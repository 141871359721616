import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Chart = ({ chart }) => {
  if (!chart) return null;

  const data = {
    labels: chart.xAxisValues, // Use xAxisValues from the JSON structure directly
    datasets: chart.series.map((s, index) => ({
      label: s.name,
      data: s.data,
      backgroundColor:
        index === 0 ? 'rgba(255, 99, 132, 0.2)' : 
        index === 1 ? 'rgba(54, 162, 235, 0.2)' : 
        'rgba(75, 192, 192, 0.2)', // Green for third series
      borderColor:
        index === 0 ? 'rgba(255, 99, 132, 1)' : 
        index === 1 ? 'rgba(54, 162, 235, 1)' : 
        'rgba(75, 192, 192, 1)', // Green border for third series
      borderWidth: 3,
    })),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: chart.xAxis, // Use the xAxis label from the JSON structure
          font: {
            size: 16, // Font size for the X-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Font size for the X-axis labels
          },
        },
      },
      y: {
        title: {
          display: true,
          text: chart.yAxis, // Use the yAxis label from the JSON structure
          font: {
            size: 16, // Font size for the Y-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Font size for the Y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14, // Font size for the legend
          },
          usePointStyle: true,
        },
      },
    },
  };

  return (
    <div className="alert alert-light">
      <div className="text-center fs-4 mb-2" style={{ color: 'black' }}>{chart.title}</div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default Chart;

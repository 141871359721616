import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import basicImage from '../assets/subscription_basic.png';
import premiumImage from '../assets/subscription_premium.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation for translations

const SettingsPage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { user } = useContext(AuthContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/api/user/subscription-status', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  const handleCancelSubscription = async () => {
    const token = localStorage.getItem('token');
    try {
      setIsLoading(true);
      await axios.post('/api/user/cancel-subscription', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubscriptionStatus('cancelled');
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = () => {
    navigate('/premium');
  };

  return (
    <div className="container mt-5 text-center">
      <h1>{t('settings')}</h1> {/* Translate "Settings" */}
      <div className="mb-3 mt-4">
        {isLoading ? (
          <p>{t('loading')}</p>
        ) : (
          <div>
            <p className="mb-0">
              <i className="bi bi-person-circle" style={{ fontSize: '100px' }}></i>
            </p>
            <p className="fs-4">{user.name}</p>
            <p className="fs-5">{user.email}</p>
            {subscriptionStatus === 'active' ? (
              <div>
                <img src={premiumImage} alt={t('premium_subscription')} style={{ height: '20px' }} /> {/* Translate alt text */}
                <br />
                <button
                  type="button"
                  className="btn btn-danger mx-3 mt-4"
                  onClick={handleCancelSubscription}
                >
                  {t('cancel_subscription')} {/* Translate "Cancel Subscription" */}
                </button>
              </div>
            ) : (
              <div>
                <img src={basicImage} alt={t('basic_subscription')} style={{ height: '20px' }} /> {/* Translate alt text */}
                <br />
                <button
                  type="button"
                  className="btn btn-primary mx-3 mt-4"
                  onClick={handleSubscribe}
                >
                  {t('upgrade')} {/* Translate "Upgrade" */}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;

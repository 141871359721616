import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const Graph = ({ graph }) => {
  if (!graph) return null;

  const data = {
    labels: graph.xAxisValues, // Use the xAxisValues from the JSON directly
    datasets: graph.series.map((s, index) => ({
      label: s.name,
      data: s.data,
      fill: false,
      borderColor: index === 0 ? 'rgba(255, 99, 132, 1)' : index === 1 ? 'rgba(54, 162, 235, 1)' : 'rgba(75, 192, 192, 1)', // Third series green
      backgroundColor: index === 0 ? 'rgba(255, 99, 132, 0.2)' : index === 1 ? 'rgba(54, 162, 235, 0.2)' : 'rgba(75, 192, 192, 0.2)', // Third series green background
      borderDash: index === 0 ? [5, 5] : index === 1 ? [15, 5] : [25, 5], // Longer dashes for third series
      borderWidth: 3, // Increased thickness for all series
    })),
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: graph.xAxis, // Use the xAxis label from the JSON
          font: {
            size: 16, // Increase font size for the X-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Increase font size for the X-axis numbers
          },
        },
      },
      y: {
        title: {
          display: true,
          text: graph.yAxis, // Use the yAxis label from the JSON
          font: {
            size: 16, // Increase font size for the Y-axis label
          },
        },
        ticks: {
          font: {
            size: 14, // Increase font size for the Y-axis numbers
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14, // Increase font size for the legend
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Optional: Add smoothness to the lines (can remove if not desired)
      },
    },
  };

  return (
    <div className="alert alert-light">
      <div className="text-center fs-4 mb-2" style={{ color: 'black' }}>{graph.title}</div>
      <Line data={data} options={options} />
    </div>
  );
};

export default Graph;

import React, { useState } from "react";
import { useFetchData } from "../hooks/useFetchData";
import { useSubmitScore } from "../hooks/useSubmitScore";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import QuestionsList from "./QuestionsList";
import HighlightedPassage from "./HighlightedPassage";
import Instructions from "./Instructions";
import HeadingsText from "./HeadingsText";
import HeadingsList from "./HeadingsList";
import GapFillingPassage from "./GapFillingPassage";
import ExerciseTitle from "./ExerciseTitle";
import BlanksList from "./BlanksList";
import TableCompletion from "./TableCompletion";
import NoteCompletion from "./NoteCompletion";
import SummaryCompletion from "./SummaryCompletion";
import ShortAnswerQuestions from "./ShortAnswerQuestions";
import useExerciseTitleFromApi from "../hooks/useExerciseTitleFromApi";
import Toolbar from "./Toolbar";
import ExerciseUsage from "./ExerciseUsage";

const ReadingExercise = ({ api }) => {
  const { data, loading, error, refetchData } = useFetchData(api);
  const { score, setScore, submitScore } = useSubmitScore();
  const [highlight, setHighlight] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);

  const handleCompletion = async (calculatedScore) => { await submitScore(data._id, calculatedScore); };
  const handleRefresh = () => { setHighlight(""); setScore(null); setResetTrigger((prev) => !prev); refetchData(); };

  return (
    <div className="container mt-4 col-xxl-10" style={{ maxWidth: '900px' }}>
      <ExerciseTitle title={useExerciseTitleFromApi(api)} onRefresh={handleRefresh} />
      <ExerciseUsage category="reading" style={{ marginBottom: '6px' }} trigger={data} />
      <Toolbar data={data} />
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage error={error} />}
      {!loading && !error && <>
        <Instructions instructions={data.content.instructions} />
        {data.content.multiple_choice && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <QuestionsList
            questions={data.content.multiple_choice}
            onHighlightChange={setHighlight}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
          />
        </>}
        {data.content.gap_filling && <GapFillingPassage
          passage={data.content.passage}
          gaps={data.content.gap_filling}
          onComplete={handleCompletion}
          resetTrigger={resetTrigger}
        />}
        {data.content.matching_headings && <>
          <HeadingsText passage={data.content.matching_headings.passage} highlight={highlight} showResults={score !== null} />
          <HeadingsList
            passage={data.content.matching_headings.passage}
            headings={data.content.matching_headings.headings}
            onHighlightChange={setHighlight}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
            showResults={score !== null}
          />
        </>}
        {data.content.sentence_completion && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <BlanksList
            blanks={data.content.sentence_completion}
            onHighlightChange={setHighlight}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
          />
        </>}
        {data.content.table_completion && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <TableCompletion
            table={data.content.table_completion.table}
            onHighlightChange={setHighlight}
            answers={data.content.table_completion.answers}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
          />
        </>}
        {data.content.note_completion && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <NoteCompletion
            notes={data.content.note_completion.notes}
            answers={data.content.note_completion.answers}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
            onHighlightChange={setHighlight}
          />
        </>}
        {data.content.summary_completion && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <SummaryCompletion
            summary={data.content.summary_completion.summary}
            answers={data.content.summary_completion.answers}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
            onHighlightChange={setHighlight}
          />
        </>}
        {data.content.short_answer_questions && <>
          <HighlightedPassage passage={data.content.passage} highlight={highlight} />
          <ShortAnswerQuestions
            short_answer_questions={data.content.short_answer_questions}
            onComplete={handleCompletion}
            resetTrigger={resetTrigger}
            onHighlightChange={setHighlight}
          />
        </>}
      </>}
    </div>
  );
};

export default ReadingExercise;

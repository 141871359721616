import React, { useState, useEffect } from "react";
import Input from "./Input"; // Import the Input component
import Explanations from "./Explanations"; // Import the Explanations component

// Helper function to check if all blanks are filled
const areAllBlanksFilled = (userAnswers, answers) => {
  return answers.every((answer) => userAnswers[answer.placeholder]?.submitted);
};

// Helper function to calculate score
const calculateScore = (userAnswers, answers) => {
  return answers.filter(
    (answer) => userAnswers[answer.placeholder]?.isCorrect
  ).length / answers.length;
};

const TableCompletion = ({ table, answers, onComplete, resetTrigger, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  // Reset when the resetTrigger changes
  useEffect(() => {
    setUserAnswers({});
    setExplanations([]);
  }, [resetTrigger]);

  // Handle input change for each blank (identified by the placeholder)
  const handleInputChange = (placeholder, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [placeholder]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  // Handle submission for each blank (identified by the placeholder)
  const handleSubmit = (placeholder) => {
    const userAnswer = userAnswers[placeholder]?.userAnswer;

    // Find the corresponding answer object using the placeholder
    const answerObj = answers.find(
      (answer) => answer.placeholder === placeholder
    );

    if (userAnswer && answerObj) {
      const isCorrect = userAnswer.trim().toLowerCase() === answerObj.correctAnswer.toLowerCase();

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [placeholder]: { userAnswer, isCorrect, submitted: true },
        };

        // Add explanation with the correct number and text
        setExplanations((prevExplanations) => [
          ...prevExplanations,
          { number: placeholder.match(/\d+/)[0], text: answerObj.explanation }
        ]);

        // Trigger the highlight function with the reference from the passage
        onHighlightChange(answerObj.reference);

        // If all blanks are filled, calculate and submit the score
        if (areAllBlanksFilled(updatedAnswers, answers)) {
          const score = calculateScore(updatedAnswers, answers);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  // Rendering the table
  const renderTable = () => (
    <table className="table table-bordered">
      <thead>
        <tr>
          {table.columns.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {table.rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>
                {/* If the cell contains a placeholder like _1_, _2_, render input field */}
                {/^_\d+_$/.test(cell) ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <strong>({cell.match(/\d+/)[0]})</strong> {/* Render number before Input */}
                    <Input
                      userAnswer={userAnswers[cell]?.userAnswer}
                      onInputChange={(value) => handleInputChange(cell, value)}
                      onSubmit={() => handleSubmit(cell)}
                      isSubmitted={userAnswers[cell]?.submitted}
                      isCorrect={userAnswers[cell]?.isCorrect}
                      correctAnswer={answers.find((a) => a.placeholder === cell)?.correctAnswer}
                    />
                  </div>
                ) : (
                  cell // Render non-blank cells
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      {/* Render the table */}
      {renderTable()}

      {/* Render explanations */}
      {explanations.length > 0 && <Explanations explanations={explanations} />}
    </div>
  );
};

export default TableCompletion;

import React, { useState, useEffect } from "react";
import Input from "./Input"; // Import the Input component

// Helper function to check if all blanks are filled
const areAllBlanksFilled = (userAnswers, answers) => {
  return answers.every((answer) => userAnswers[answer.placeholder]?.submitted);
};

// Helper function to calculate score
const calculateScore = (userAnswers, answers) => {
  return answers.filter(
    (answer) => userAnswers[answer.placeholder]?.isCorrect
  ).length / answers.length;
};

const NoteCompletion = ({ notes, answers, onComplete, resetTrigger, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});

  // Reset when the resetTrigger changes
  useEffect(() => {
    setUserAnswers({});
  }, [resetTrigger]);

  // Handle input change for each blank (identified by the placeholder)
  const handleInputChange = (placeholder, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [placeholder]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  // Handle submission for each blank (identified by the placeholder)
  const handleSubmit = (placeholder) => {
    const userAnswer = userAnswers[placeholder]?.userAnswer;

    // Find the corresponding answer object using the placeholder
    const answerObj = answers.find(
      (answer) => answer.placeholder === placeholder
    );

    if (userAnswer && answerObj) {
      const isCorrect = userAnswer.trim().toLowerCase() === answerObj.correctAnswer.toLowerCase();

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [placeholder]: { userAnswer, isCorrect, submitted: true, explanation: answerObj.explanation },
        };

        // Trigger the highlight function with the reference from the passage
        onHighlightChange(answerObj.reference);

        // If all blanks are filled, calculate and submit the score
        if (areAllBlanksFilled(updatedAnswers, answers)) {
          const score = calculateScore(updatedAnswers, answers);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  // Rendering the notes with bullet points, input fields, and explanations inline
  const renderNotes = () => (
    <div>
      {/* Center the notes heading */}
      <h4 className="text-center mb-4">{notes.heading}</h4>
      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}> {/* Ensure enough padding for bullets */}
        {notes.items.map((note, index) => (
          <li key={index} className="mb-3" style={{ listStyleType: "disc" }}>
            {/* Replace the placeholders with input fields */}
            <div style={{ display: "inline" }}>
              {note.split(/(_\d+_)/).map((part, i) => {
                if (/^_\d+_$/.test(part)) {
                  const placeholder = part;
                  const userAnswer = userAnswers[placeholder];
                  return (
                    <span className="mx-1" key={i} style={{ display: "inline-flex", alignItems: "center" }}>
                      <Input
                        userAnswer={userAnswer?.userAnswer}
                        onInputChange={(value) => handleInputChange(placeholder, value)}
                        onSubmit={() => handleSubmit(placeholder)}
                        isSubmitted={userAnswer?.submitted}
                        isCorrect={userAnswer?.isCorrect}
                        correctAnswer={answers.find((a) => a.placeholder === placeholder)?.correctAnswer}
                      />
                    </span>
                  );
                }
                return <span key={i}>{part}</span>;
              })}
            </div>

            {/* If the note is submitted, show the explanation directly below it */}
            {userAnswers[`_${index + 1}_`] && userAnswers[`_${index + 1}_`].submitted && (
              <div className={`alert alert-warning mt-2`}>
                {userAnswers[`_${index + 1}_`].explanation}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div>
      {/* Render the notes */}
      {renderNotes()}
    </div>
  );
};

export default NoteCompletion;

import React from "react";

const BlankItem = ({ index, question, userAnswer, result, onInputChange, onSubmit, onHover, onLeave }) => {
  // Handle key press for submitting on "Enter"
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit(index);
    }
  };

  const renderSentenceWithBlanks = () => {
    const parts = question.sentence.split("_");

    return (
      <div className="mb-3">
        <span>{index + 1}.&nbsp;</span>
        <div style={{ display: "inline", whiteSpace: "normal" }}>
          {parts.map((part, i) => (
            <React.Fragment key={i}>
              <span>{part}</span>
              {i < parts.length - 1 && (
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  {result ? (
                    <div className="btn-group d-inline-block mx-1" role="group">
                      <button
                        className={`btn ${result.isCorrect ? "btn-success" : "btn-danger"}`}
                        disabled
                      >
                        {result.userAnswer}
                      </button>
                      {!result.isCorrect && (
                        <button className="btn btn-success" disabled>
                          {result.correctAnswer}
                        </button>
                      )}
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="form-control d-inline-block mx-1"
                      style={{ width: "150px" }}
                      onChange={(e) => onInputChange(index, e.target.value)}
                      onKeyPress={handleKeyPress} // Add onKeyPress handler
                      value={userAnswer || ""}
                    />
                  )}
                </span>
              )}
            </React.Fragment>
          ))}
          {!result && (
            <button
              className="btn btn-link mx-2 p-0"
              onClick={() => onSubmit(index)}
              style={{ fontSize: "24px" }} // Adjust the size of the icon
            >
              <i className="bi bi-arrow-right-circle-fill"></i> {/* Bootstrap Icon */}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="question-item mb-2" onMouseEnter={onHover} onMouseLeave={onLeave}>
      {renderSentenceWithBlanks()}
      {result && (
        <div className="alert alert-warning mt-2" role="alert">
          {result.explanation || "No explanation provided."}
        </div>
      )}
    </div>
  );
};

export default BlankItem;

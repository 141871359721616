import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation for translations

const highlightText = (text, reference) => {
  if (!reference) return text;

  // Escape special characters for RegExp
  const escapedReference = reference.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedReference})`, 'gi')); // Split on reference and include reference in the result
  return parts.map((part, index) => 
    part.toLowerCase() === reference.toLowerCase() ? <mark key={index}>{part}</mark> : part
  );
};

const Transcript = ({ conversation, highlight }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [isTranscriptVisible, setTranscriptVisible] = useState(false);

  const toggleTranscriptVisibility = () => {
    setTranscriptVisible(!isTranscriptVisible);
  };

  return (
    <div className="mb-4">
      <h5
        className="d-inline-block"
        style={{ cursor: 'pointer' }}
        onClick={toggleTranscriptVisibility}
        aria-expanded={isTranscriptVisible}
        aria-controls="transcriptCollapse"
      >
        {t('transcript')} {/* Translate "Transcript" */}
        <i className={`bi ${isTranscriptVisible ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
      </h5>
      <div id="transcriptCollapse" className={`collapse ${isTranscriptVisible ? 'show' : ''}`}>
        <div className="alert alert-light mt-2">
          {conversation.map((part, index) => (
            <div key={index}>
              <strong>{t('speaker')} {part.speaker}:</strong> {/* Translate "Speaker" */}
              {highlightText(part.text, highlight)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Transcript;

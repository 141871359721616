import React from 'react';
import ScorePlot from './ScorePlot'; // Assuming you have the ScorePlot component

const ToolbarScorePlot = ({ data }) => {
  if (!data) return null; // Handle cases where data is not available

  return (
    <div style={{ width: '100px', height: '40px', margin: '0 auto' }}>
      <ScorePlot 
        targetLanguage={data.targetLanguage}
        level={data.level}
        section={data.section} 
        exercise={data.exercise}
        aspectratio='2.25'
        hidexticks
      />
    </div>
  );
};

export default ToolbarScorePlot;

import React from "react";

const Input = ({ userAnswer, onInputChange, onSubmit, isSubmitted, isCorrect, correctAnswer }) => {
  // Handle key press for submitting on "Enter"
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isSubmitted ? (
        <div className="btn-group d-inline-block mx-2" role="group">
          <button className={`btn ${isCorrect ? "btn-success" : "btn-danger"}`} disabled>
            {userAnswer}
          </button>
          {!isCorrect && (
            <button className="btn btn-success" disabled>
              {correctAnswer}
            </button>
          )}
        </div>
      ) : (
        <>
          <input
            type="text"
            className="form-control d-inline-block mx-2"
            style={{ width: "150px" }}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyPress={handleKeyPress}
            value={userAnswer || ""}
          />
          <button
            className="btn btn-link mx-0 p-0"
            onClick={onSubmit}
            style={{ fontSize: "24px" }}
          >
            <i className="bi bi-arrow-right-circle-fill"></i> {/* Bootstrap icon */}
          </button>
        </>
      )}
    </div>
  );
};

export default Input;

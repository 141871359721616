import React, { useState, useEffect } from "react";
import Input from "./Input"; // Import the Input component
import Explanations from "./Explanations"; // Import the modified Explanations component

// Helper function to check if all blanks are filled
const areAllBlanksFilled = (userAnswers, answers) => {
  return answers.every((answer) => userAnswers[answer.placeholder]?.submitted);
};

// Helper function to calculate score
const calculateScore = (userAnswers, answers) => {
  return answers.filter(
    (answer) => userAnswers[answer.placeholder]?.isCorrect
  ).length / answers.length;
};

const SummaryCompletion = ({ summary, answers, onComplete, resetTrigger, onHighlightChange }) => {
  const [userAnswers, setUserAnswers] = useState({});
  const [explanations, setExplanations] = useState([]);

  // Reset when the resetTrigger changes
  useEffect(() => {
    setUserAnswers({});
    setExplanations([]);
  }, [resetTrigger]);

  // Handle input change for each blank (identified by the placeholder)
  const handleInputChange = (placeholder, value) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [placeholder]: {
        userAnswer: value,
        isCorrect: false,
        submitted: false,
      },
    }));
  };

  // Handle submission for each blank (identified by the placeholder)
  const handleSubmit = (placeholder) => {
    const userAnswer = userAnswers[placeholder]?.userAnswer;

    // Find the corresponding answer object using the placeholder
    const answerObj = answers.find(
      (answer) => answer.placeholder === placeholder
    );

    if (userAnswer && answerObj) {
      const isCorrect = userAnswer.trim().toLowerCase() === answerObj.correctAnswer.toLowerCase();

      setUserAnswers((prevAnswers) => {
        const updatedAnswers = {
          ...prevAnswers,
          [placeholder]: { userAnswer, isCorrect, submitted: true },
        };

        // Trigger the highlight function with the reference from the passage
        onHighlightChange(answerObj.reference);

        // Add explanation with the correct number and text to the explanations array
        setExplanations((prevExplanations) => [
          ...prevExplanations,
          { number: placeholder.match(/\d+/)[0], text: answerObj.explanation }
        ]);

        // If all blanks are filled, calculate and submit the score
        if (areAllBlanksFilled(updatedAnswers, answers)) {
          const score = calculateScore(updatedAnswers, answers);
          onComplete(score);
        }

        return updatedAnswers;
      });
    }
  };

  // Rendering the summary with input fields and explanations inline
  const renderSummary = () => (
    <div>
      {/* Render the summary as continuous text */}
      <p style={{ textAlign: "justify" }}>
        {summary.split(/(_\d+_)/).map((part, i) => {
          if (/^_\d+_$/.test(part)) {
            const placeholder = part;
            const placeholderNumber = placeholder.match(/\d+/)[0]; // Extract the number
            const userAnswer = userAnswers[placeholder];
            return (
              <span className="mx-1" key={i} style={{ display: "inline-flex", alignItems: "center" }}>
                <strong>({placeholderNumber})</strong> {/* Add number in bold */}
                <Input
                  userAnswer={userAnswer?.userAnswer}
                  onInputChange={(value) => handleInputChange(placeholder, value)}
                  onSubmit={() => handleSubmit(placeholder)}
                  isSubmitted={userAnswer?.submitted}
                  isCorrect={userAnswer?.isCorrect}
                  correctAnswer={answers.find((a) => a.placeholder === placeholder)?.correctAnswer}
                />
              </span>
            );
          }
          return <span key={i}>{part}</span>;
        })}
      </p>

      {/* Render explanations */}
      {explanations.length > 0 && <Explanations explanations={explanations} />}
    </div>
  );

  return (
    <div>
      {/* Render the summary */}
      {renderSummary()}
    </div>
  );
};

export default SummaryCompletion;

import React from "react";
import { useTranslation } from 'react-i18next';
import Stars from "./Stars";
import highlightCorrections from "../utils/highlightCorrections";
import highlightExamples from "../utils/highlightExamples";

const FeedbackSpeaking = ({ feedback }) => {
  const { t } = useTranslation();

  return (
    <div className="alert alert-warning mt-3" role="alert">
      <h2 className="text-center mb-3"><Stars score={feedback.general.score} /></h2>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-chat-dots me-2"></i>{t('transcript')}:&nbsp;&nbsp;
        </h3>
        <div>{highlightCorrections(feedback.correction)}</div>
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-graph-up me-2"></i>{t('feedback')}:&nbsp;&nbsp;
          <Stars score={feedback.general.score} />
        </h3>
        <div>{highlightExamples(feedback.general.feedback)}</div>
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-chat-dots me-2"></i>{t('fluency')}:&nbsp;&nbsp;
          <Stars score={feedback.fluency.score} />
        </h3>
        <div>{highlightExamples(feedback.fluency.feedback)}</div>
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-book me-2"></i>{t('vocabulary')}:&nbsp;&nbsp;
          <Stars score={feedback.vocabulary.score} />
        </h3>
        <div>{highlightExamples(feedback.vocabulary.feedback)}</div>
      </div>
      <div className="mb-4">
        <h3 className="fs-5">
          <i className="bi bi-gear me-2"></i>{t('grammar')}:&nbsp;&nbsp;
          <Stars score={feedback.grammar.score} />
        </h3>
        <div>{highlightExamples(feedback.grammar.feedback)}</div>
      </div>
      <div>
        <h3 className="fs-5">
          <i className="bi bi-mic me-2"></i>{t('pronunciation')}:&nbsp;&nbsp;
          <Stars score={feedback.pronunciation.score} />
        </h3>
        <div>{highlightExamples(feedback.pronunciation.feedback)}</div>
      </div>
    </div>
  );
};

export default FeedbackSpeaking;
